import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { getUserRegion } from '../hooks/useRoom/helpers';
import { logEvent } from '../utils/debug';
import Ping from '../utils/ping';
import { userAPI } from '../api/user';

interface RegionConfig {
  name: string;
  url: string;
}

const RegionContainer = () => {
  const {
    auth: { user },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!user) return;

    const { countryByPhone, meetRegion } = user || {};
    const userRegion = getUserRegion(countryByPhone, meetRegion);

    logEvent({
      event: 'region',
      data: {
        meetRegion,
        userRegion,
        countryByPhone,
      },
    });

    setTimeout(async () => {
      const p = new Ping({ favicon: 'images/favicon.svg' });

      const config: RegionConfig[] = [
        { name: 'eu-east', url: 'https://ji-spb.progkids.com/' },
        { name: 'me-north', url: 'https://jvb-tur.progkids.com/' },
        { name: 'ap-southeast', url: 'https://jvb-sgp.progkids.com/' },
      ];

      const data: { [key: string]: any } = {};
      for (const c of config) {
        data[c.name] = await p.ping(c.url, 10);
      }

      let minMinimum = Number.MAX_VALUE;
      let minMedian = Number.MAX_VALUE;
      let minRegion: string = '';

      Object.keys(data).forEach((region) => {
        const regionData = data[region];
        if (regionData.min < minMinimum && regionData.median < minMedian) {
          minRegion = region;
          minMinimum = regionData.min;
          minMedian = regionData.median;
        }
      });

      if (minRegion) {
        userAPI.setRegion(minRegion).catch(console.log);
      }

      logEvent({ event: 'region_ping', data });
    }, 10 * 1000);
  }, [user]);

  return null;
};

export default RegionContainer;
